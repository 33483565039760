import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['externalPaymentUrl'];

  navigateToExternalUrl() {

    let self = this;
    var windowReference = window.open();

    self.stimulate('TourProductReflex#proceed_to_mercado_pago'); //.then(() => {
    windowReference.target = "_blank";
    windowReference.location = self.externalPaymentUrlTarget.dataset.externalUrl;
    // })
  }
}