import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['focus', 'dniInput', 'ageInput','lastNameInput', 'firstNameInput', 'phoneInput', 'emailInput', 'emailConfirmationInput', 'isOwnerInput', 'quantityInput']
  static values = {
    submitForm: String
  }
  submit (e) {
    let hasErrors = false;
    let self = this;
    let targets = ['emailInput',
                   'emailConfirmationInput',
                   'phoneInput',
                   'dniInput',
                   'ageInput',
                   'lastNameInput',
                   'firstNameInput',
                   'quantityInput']

    targets.forEach(function(targetName) {
      let result = self.validateField(targetName);
      if(!hasErrors) {
        hasErrors = result;
      }
    })

    if(hasErrors) {
      e.preventDefault();
      return;
    }

    if(self.submitFormValue) {
      // No op -> submits the form
    } else {
      e.preventDefault();
      self.stimulate('CompanionForm#save_companion').then(() => {
        self.element.reset()
        // optional: set focus on the freshly cleared input
        if(self.hasDniInputTarget) {
          self.dniInputTarget.focus()
        }
      }).catch((e) => { 
        alert('DNI DUPLICADO');
      })
    }
  }

  validateOnChange(event) {
    let targetName = event.target.dataset['reflexFormTarget'];
    let hasErrors = this.validateField(targetName);
  }

  validateField(targetName) {

    if(!this.targets.has(targetName)) {
      return false;
    }

    let target = this[`${targetName}Target`];

    if(target.value == "") {
      this.errorTarget(target);
      return true;
    } 

    if(targetName == 'quantityInput' && (target.value <= 0)) {
      this.errorTarget(target);
      return true;
    }

    if(targetName == 'dniInput' && (target.value.length < 7 || target.value.length > 8)) {
      this.errorTarget(target);
      return true;
    }

    if(targetName == 'ageInput' && (parseInt(target.value) < 18 && this.isOwnerInputTarget.value == 'true' || parseInt(target.value) > 110 || parseInt(target.value) < parseInt(target.min))) {
      this.errorTarget(target);
      if(parseInt(target.value) < parseInt(target.min)) {
        alert("Superaste la cantidad de menores por adulto. Esta limitación es para preservar la tranquildad de los animales y velar por la seguridad de los menores. Por favor, para cargar la reserva de más menores, ingresá antes los datos de otro adulto. Muchas gracias.")
      }
      return true;
    }

    if(targetName == 'emailInput' || targetName == 'emailConfirmationInput') {
      if(targetName == 'emailInput' && !this.validateEmail(target.value)) {
        this.errorTarget(target);
        return true;
      }

      if(this.emailInputTarget.value != this.emailConfirmationInputTarget.value) {
        if(targetName == 'emailInput') {
          this.errorTarget(this.emailConfirmationInputTarget);
        } else {
          this.errorTarget(this.emailInputTarget);
        }
        return true;
      } else {
        this.successTarget(this.emailInputTarget);
        this.successTarget(this.emailConfirmationInputTarget);
      }
    }

    this.successTarget(target);
    return false;
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  errorTarget(target) {
    target.classList.add('border-1');
    target.classList.add('border-red-300');
    target.classList.remove('border-green');
  }

  successTarget(target) {
    target.classList.remove('border-1');
    target.classList.remove('border-red-300');
    target.classList.add('border-green');
  }
}