import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['focus', 'studentCountsInput', 'phoneInput', 'courseInput', 'institutionInput']
  submit (e) {
    e.preventDefault();
    let hasErrors = false;
    let self = this;
    let targets = ['institutionInput',
                   'courseInput',
                   'phoneInput',
                   'studentCountsInput']

    targets.forEach(function(targetName) {
      let result = self.validateField(targetName);
      if(!hasErrors) {
        hasErrors = result;
      }
    })

    if(!hasErrors) {
      self.stimulate('InstitutionalTourProduct#proceed_to_confirmation', { booking_id: e.target.dataset['tourBookingId'], institution_name: self.institutionInputTarget.value, course: self.courseInputTarget.value, students_count: self.studentCountsInputTarget.value, institution_phone: self.phoneInputTarget.value }).then(() => {
        self.element.reset()
        // optional: set focus on the freshly cleared input
        self.studentCountsInputTarget.focus()
      })
    }
  }

  validateOnChange(event) {
    let targetName = event.target.dataset['institutionDetailsFormTarget'];
    let hasErrors = this.validateField(targetName);
  }

  validateField(targetName) {
    if(!this.targets.has(targetName)) {
      return false;
    }

    let target = this[`${targetName}Target`];

    if(target.value == "") {
      this.errorTarget(target);
      return true;
    } 

    this.successTarget(target);
    return false;
  }

  errorTarget(target) {
    target.classList.add('border-1');
    target.classList.add('border-red-300');
    target.classList.remove('border-green');
  }

  successTarget(target) {
    target.classList.remove('border-1');
    target.classList.remove('border-red-300');
    target.classList.add('border-green');
  }
}