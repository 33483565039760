import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scrollDownArrow", "showHide" ]

  onScroll() {
    if(this.hasScrollDownArrowTarget) {
      this.scrollDownArrowTarget.classList.add("invisible")
    }
  }

  toggleShowHide() {
    this.showHideTarget.classList.toggle('hidden');
  }
}
