import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "minutesRemaining", "secondsRemaining", "counterDisplay" ]

  connect () {
    this.startTimer();
  }

  startTimer() {
    var minute = this.minutesRemainingTarget.value;
    var sec = this.secondsRemainingTarget.value;
    let self = this;

    let clearIntervalIn = ((parseInt(minute) * 60) + parseInt(sec)) * 1000;

    if(clearIntervalIn > 0) {
      setTimeout(function() {
        alert("Expiraron tus 10 minutos para completar la reserva. Debes volver a seleccionar un horario. Guardaremos la información ingresada de los visitantes.")
        location.reload();
      }, clearIntervalIn)
    }
    
    let intervalId = setInterval(function () {
      if(sec < 10) {
        sec = `0${sec}`
      }
      if(minute >= 0) {
      self.counterDisplayTarget.innerHTML =
        minute + " : " + sec;
      }
      sec--;
      if (sec == 0) {
        minute--;
        sec = 60;
        // if (minute == 0) {
        //    minute = -1;
        // }
      }
    }, 1000);
  }
}
